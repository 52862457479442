<template>
    <v-container fluid>
        <PageLayout>
            <TabsNav />
            <div class="d-flex justify-space-between w-100 mb-10">
                <h1>{{ $t('departments.head') }}</h1>

                <div v-if="can(['administrator','director', 'hr_manager'])" class="text-right d-flex align-center">
                    <v-icon class="mr-5" color="secondary" @click="filter_show = !filter_show">mdi-filter-plus</v-icon>
                    <DepartmentsButtons></DepartmentsButtons>
                </div>

                <FilterComponent
                    v-if="filter_show"
                    :filterData="filterData"
                    :currentFilter="currentFilter"
                    :value="filterValues"
                    :loading="loading"
                    :entity="entityName"
                    @input="filterValues = $event"
                    @submit="submitForm"
                    @filterSaved="filterSaved"
                    @filterDeleted="filterDeleted"
                />
            </div>
            <v-btn
                class="mb-6"
                v-if="parent"
                text
                color="primary"
                :to="{
                url: '/department',
                query: {
                    filter: {
                        parent_id: {
                            eq: (parent.parent_id ?? 0) + ''
                        }
                    }
                }
            }">
                <v-icon>mdi-keyboard-return</v-icon>{{$t('departments.up_to_the_level')}}
            </v-btn>
            <ResizableTable
                class="main-data-table"
                :rows="departments"
                :columns="filteredHeaders.map((item) => {
                        return {
                            ...item,
                            prop: item.value,
                            label: item.text,
                            width: 'auto'
                        }
                    })"
            >
                <template v-slot:children_link="{ item }">
                    <v-btn
                        v-if="item.children_count > 0"
                        text
                        color="primary"
                        :to="{
                        url: '/department',
                        query: {
                            filter: {
                                parent_id: {
                                    eq: item.id + ''
                                }
                            }
                        }
                    }">{{$t('departments.fields.child_departments')}} [{{item.children_count}}]</v-btn>
                </template>

                <template v-slot:name="{ item }">
                    <div :class="{ 'red--text': item.deleted }">
                        <span class="cursor-pointer font_weight_600" @click="edit(item)">
                            {{ item.name }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </span>
                        <v-icon v-if="!item.deleted" @click="edit(item)" small>mdi-pencil-outline</v-icon>
                        <v-icon v-if="!item.deleted" @click="del(item)" small>mdi-delete-outline</v-icon>
                    </div>
                </template>

                <template v-slot:parent="{ item }">
                    {{ item.parent ?? 'Верхний уровень' }}
                </template>

                <template v-slot:members="{ item }">
                    <div v-if="item.members && item.members.length > 0">
                        <div class="avatars cursor-pointer">
                            <span class="avatar" v-for="(member, index) in item.members"  :key="index">
                                <v-avatar size="36" >
                                    <img v-if="member.photo" :title="member.name" :src="member.photo" :alt="member.name">
                                    <img v-else :title="member.name" class="avatar_border avatar_border_prime" src="/img/avatar.svg" :alt="member.name">
                                </v-avatar>
                            </span>
                        </div>
                    </div>
                    <div v-else></div>
                </template>
                <template v-slot:head="{ item }">
                    <div v-if="item.head">
                        <div class="avatars cursor-pointer">
                            <v-avatar size="36">
                                <img v-if="item.head.photo !== '/img/avatar.png'" :title="item.head.name" class="" :src="item.head.photo" :alt="item.head.name">
                                <img v-else :title="item.head.name" class="avatar_border" src="/img/avatar.svg" :alt="item.head.name">
                            </v-avatar>
                        </div>
                    </div>
                </template>
            </ResizableTable>
            <v-pagination v-model="options.page" :total-visible="totalVisiblePag" class="pagination_table" :length="options.pageCount" :disabled="loading"></v-pagination>
        </PageLayout>
    </v-container>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout";
import {mapActions, mapGetters} from "vuex"
import {mask} from 'vue-the-mask'
import FilterView from "@/plugins/mixins/FilterView";
import DepartmentsButtons from "@/components/DepartmentsButtons.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";

export default {
    name: "Administrators",
    directives: {
        mask,
    },
    components: {
        TabsNav,
        DepartmentsButtons,
        PageLayout
    },

    mixins: [
        FilterView
    ],

    data() {
        return {
            entityName: 'department',
            parent: null,

            fab: false,
            phoneRaw: null,
            administrator: null,
            email: null,
            departments: [],
            departmentItems: [],
            active: 1,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
            totalVisiblePag: 7,
            totalAdministrators: 0,
            loading: false,
            headers: [
                {
                    active: true,
                    text: 'id',
                    align: "left",
                    sortable: true,
                    value: "id"
                },
                {
                    active: true,
                    text: this.$t('departments.fields.name'),
                    align: "left",
                    sortable: true,
                    value: "name"
                },
                {
                    active: true,
                    text: this.$t('departments.fields.depth'),
                    align: "left",
                    sortable: true,
                    value: "depth"
                },
                {
                    active: true,
                    text: this.$t('departments.fields.head'),
                    align: "left",
                    sortable: true,
                    value: "head"
                },
                {
                    active: true,
                    text: this.$t('departments.fields.members'),
                    align: "left",
                    sortable: true,
                    value: "members"
                },
                {
                    active: true,
                    text: this.$t('departments.fields.parent'),
                    align: "left",
                    sortable: true,
                    value: "parent.id"
                },
                {
                    active: true,
                    text: this.$t('departments.fields.child_departments'),
                    align: "left",
                    sortable: true,
                    value: "children_link"
                },
            ],
            employee_position: null,
            employee_positionItems: [],
            system_filters: [
                {'title': 'Все контакты', 'count': '10'},
                {'title': 'Мои клиенты', 'count': '0'},
                {'title': 'Избранное', 'count': '7'},
            ],
            Additional: [],
            group1: true,
            group2: true,
            filter_show: false,
            deletePromt: false,
            assign_admin: null,
            adminItems: [],
            delete_admin_id: null,
        }
    },

    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask', 'formAdministrator']),

        phone: function () {
            return this.changePhone(this.phoneRaw)
        },

        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },

    methods: {
        ...mapActions(['setFormAdministrator']),

        init() {
            this.getFilters()
            this.getItems()
            this.getParent();
        },

        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass' : '';
        },

        edit(item) {
            this.$router.push({
                name: 'department.edit',
                params: {
                    id: item.id
                }
            })
        },

        getParent() {
            let parentId = this.$route.query.filter?.parent_id?.eq ?? '0';

            if(parentId) {
                this.getItem(parentId).then((d) => {
                    this.parent = d;
                })
            } else {
                this.parent = null;
            }
        },

        async getItem(id) {
            this.loading = true;
            let res = await this.$http.get(`admin/department`, {
                params: {
                    "fltr": {
                        "id": {
                            "eq": id + ''
                        }
                    }
                }
            })

            this.loading = false;

            if (res.status >= 200 && res.status < 300) {
                return res.body.data[0];
            } else {
                this.$toastr.error(this.$t('departments.failed_to_get_list_departments'))
            }
        },

        async getItems(type) {
            this.loading = true
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;

            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }

            if (this.filterValues) {
                params.fltr = this.filterValues;
            }

            await this.$http
                .get("admin/department", {
                    params: params,
                })
                .then(res => {
                    this.departments = res.body.data

                    if (res.body.meta) {
                        this.parent = '';
                        this.page = res.body.meta.current_page;
                        this.totalAdministrators = res.body.meta.total;
                        this.pageCount = res.body.meta.last_page;
                    } else {
                        this.page = 1;
                        this.totalAdministrators = res.body.data.length;
                        this.pageCount = 1;
                    }
                })
                .catch(err => {
                    this.departments = []
                    this.totalAdministrators = 0
                    this.$toastr.error(this.$t('departments.failed_to_get_list_departments'))
                })
                .finally(end => {
                    this.loading = false
                })
        },

        closeAdminListDialog() {
            this.deletePromt = false
            this.assign_admin = null
        },

        deleteShow(item) {
            this.deletePromt = true
            this.assign_admin = null
            this.delete_admin_id = item.id
        },

        async getAdmins() {
            this.loading = true
            let params = {};
            params.active = this.active
            params.perPage = 1000

            if (this.filterValues) {
                params.fltr = this.filterValues;
            }

            await this.$http
                .get("admin/admin", {
                    params: params,
                })
                .then(res => {
                    this.adminItems = res.body.data
                })
                .catch(err => {
                    this.adminItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },

        async del(item) {
            if(confirm(this.$t('departments.prompt_delete'))) {
                await this.$http.delete(`admin/department/${item.id}`)
                    .then(function (resp) {
                        this.$toastr.success(this.$t('departments.success_to_delete_department'))
                        this.closeAdminListDialog()
                        this.getItems()
                    })
                    .catch(function () {
                        this.$toastr.error(this.$t('departments.failed_to_delete_department'))
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
    }
}
</script>

<style lang="scss">
.departments-table {
    tr {
        &.rowHeadClass,
        &.rowClass {
            td, th {
                width: calc(100% / 7) !important;
            }
        }
    }
}
</style>
